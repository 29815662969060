@import "codemirror/lib/codemirror.css";
@import "codemirror/theme/material.css";
@import "codemirror/theme/3024-day.css";
@import "codemirror/theme/base16-light.css";
@import "codemirror/theme/duotone-light.css";
@import "codemirror/theme/eclipse.css";
@import "codemirror/theme/elegant.css";

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(41, 41, 60);
  height: 100vh;
  overflow-x: hidden;
}

code {
  text-align: left;
  font-family: monospace !important;
}

.CodeMirror {
  min-height: 300px;
  height: 50vh;
  font-size: inherit;
}
