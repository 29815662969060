.offscreen {
  position: absolute;
  left: 10000px;
}

table {
  position: absolute;
  border: 0;
}

td {
  border: 0;
}

.table {
  position: absolute;
  border: 0;
  background-color: blue;
}

.td {
  border: 0;
}
