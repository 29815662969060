#root {
  height: 100%;
}

.App {
  min-height: 100%;
  padding-bottom: 30px;
}

.button-wrapper {
  position: absolute;

  right: 10px;
  z-index: 30;
}

.buttons-top {
  top: 10px;
}

.buttons-bottom {
  bottom: 10px;
}
